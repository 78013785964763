import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper'; 
import Typography from '@material-ui/core/Typography';
import { Select, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, ButtonGroup } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { dashboardUrl } from '../utils';
import { 
    createWorkerQueryObject, 
    fetchWorkerListAndPrepare, 
    fetchQueueList, 
    languages,
    createTasksByWorkerQueryObject,
    fetchTasksByWorkerAndPrepare 
} from '../api';
import ErrorHandler from '../ErrorHandler';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



export const Workers = () => {
    const classes = useStyles();

    const cols = [
        "worker_ref",
        "task_id",
        "lang",
        "queue",
        "last_online_ts",
        "worker_status",
        "worker_active"
    ]

    const [workers, setWorkers] = useState([]);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [sortKey, setSortKey] = useState('last_online_desc');
    const [workerStatusVal, setWorkerStatusVal] = useState("");
    const [activeVal, setActiveVal] = useState("")
    const [queues, setQueues] = useState([]);
    const [queueVal, setQueueVal] = useState("");
    const [langVal, setLangVal] = useState("");
    const [startDateVal, setStartDateVal] = useState("");
    const [endDateVal, setEndDateVal] = useState("");
    const [refresh, setRefresh] = useState(false);

    const updateTable = (
        sortKey,
        limit, 
        page, 
        queueVal,
        langVal,
        workerStatusVal,
        activeVal,
        startDateVal,
        endDateVal
    ) => {
        const queryObject = createWorkerQueryObject(
                                sortKey,
                                limit, 
                                page, 
                                queueVal,
                                langVal,
                                workerStatusVal,
                                activeVal,
                                startDateVal,
                                endDateVal
                            )
        fetchWorkerListAndPrepare(queryObject).then((obj) => {
            setTotal(obj.meta.total);
            setWorkers(obj.workers);
        });
        fetchQueueList().then((queues) => {
            setQueues(queues);
        });
    }

    useEffect(() => {
        updateTable(
            sortKey,
            limit, 
            page, 
            queueVal,
            langVal,
            workerStatusVal,
            activeVal,
            startDateVal,
            endDateVal
        );
        const autoUpdates = setInterval(() => {
            updateTable(
                sortKey,
                limit, 
                page, 
                queueVal,
                langVal,
                workerStatusVal,
                activeVal,
                startDateVal,
                endDateVal
            );
        }, 10000)

        return () => clearInterval(autoUpdates);

    }, [sortKey,
        limit, 
        page, 
        total, 
        queueVal,
        langVal,
        workerStatusVal,
        activeVal,
        startDateVal,
        endDateVal,
        refresh]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
    };

    const handleSortKeyChange = () => {
        setSortKey(sortKey === 'last_online_desc' ? 'last_online_asc' : 'last_online_desc');
    }

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(1);
    }

    const handleStartDateVal = (event) => {
        setStartDateVal(event.target.value);
    }

    const handleEndDateVal = (event) => {
        setEndDateVal(event.target.value);
    }

    const handleReset = () => {
        setQueueVal("");
        setLangVal("");
        setStartDateVal("")
        setEndDateVal("");
        setActiveVal("");
        setWorkerStatusVal("");
    }

    const headRenders = {
        "worker_ref": "Worker",
        "task_id": "Task ID",
        "lang": "Language",
        "queue": "Queue",
        "last_online_ts": <TableSortLabel active direction={sortKey === 'last_online_desc' ? 'desc' : 'asc'} onClick={handleSortKeyChange}>Last Online</TableSortLabel>,
        "worker_status": "Worker Status",
        "worker_active": "State"
    };

    const bodyRenders = {
        "worker_ref": (worker) => worker.worker_ref,
        "task_id": (worker) => (<a href={dashboardUrl + "task/"+worker.task_id} style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer">
                                <Button>{worker.task_id}</Button>
                                </a>),
        "lang": (worker) => worker.lang,
        "queue": (worker) => worker.queue,
        "last_online_ts": (worker) => worker.last_online_ts.toUTCString(),
        "worker_status": (worker) => worker.worker_status,
        "worker_active": (worker) => worker.worker_active ? "ACTIVE" : "INACTIVE"
    };

    return (
        <React.Fragment>
             <Typography variant='h5'>
                <strong>Workers</strong><IconButton onClick={() => setRefresh(!refresh)}><RefreshIcon /></IconButton>
            </Typography>
            <br />
            <ErrorHandler>
                <div>
                <Paper id='filters'>
                        <div style={{padding: "10px 20px 10px 20px"}}>
                            <Typography>
                                <strong>Filters</strong>
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField id="queue" label="Queue" value={queueVal} onChange={(event) => setQueueVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField id="language" label="Language" value={langVal} onChange={(event) => setLangVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="active-select-label">State</InputLabel>
                                <Select
                                    labelId="active-select-label"
                                    id="active-select"
                                    value={activeVal}
                                    onChange={(event) => setActiveVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="1"><em>ACTIVE</em></MenuItem>
                                    <MenuItem value="0"><em>INACTIVE</em></MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="worker-status-select-label">Worker Status</InputLabel>
                                <Select
                                    labelId="worker-status-select-label"
                                    id="worker-status-select"
                                    value={workerStatusVal}
                                    onChange={(event) => setWorkerStatusVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="0"><em>IDLE</em></MenuItem>
                                    <MenuItem value="1"><em>PROCESSING</em></MenuItem>
                                    <MenuItem value="2"><em>COMPLETED</em></MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Last Online-From" 
                                    value={startDateVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{max: endDateVal}}
                                    onChange={handleStartDateVal}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Last Online-To" 
                                    value={endDateVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{min: startDateVal}}
                                    onChange={handleEndDateVal}
                                />
                            </FormControl>
                            <br />
                            <FormControl className={classes.formControl}>
                                <ButtonGroup>
                                    <Button onClick={handleReset}>Reset</Button>
                                    {/* <Button onClick={handleExport}>Export</Button> */}
                                </ButtonGroup>
                            </FormControl>
                        </div>
                    </Paper>
                </div>
                <br />
                <Paper>
                    <TableContainer style={{maxHeight: '70vh'}}>
                        <Table style={{minWidth: 700}} stickyHeader size="small" aria-label="worker table">
                            <TableHead>
                                <TableRow>
                                    {cols.map((col) => {
                                        return <TableCell key={col}><strong>{headRenders[col]}</strong></TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workers.length > 0
                                    ? workers.map((worker) => (
                                        <TableRow key={worker.worker_ref}>
                                            {cols.map((col) => <TableCell key={col}>{bodyRenders[col](worker)}</TableCell>)}
                                        </TableRow>
                                    ))
                                    :<TableRow>
                                        <TableCell align='center' colSpan={cols.length + 1}>
                                            <p style={{fontSize: 'large', margin: 0}}>No Workers to display</p>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page-1} // TablePagination's pages start at 0, API's start at 1
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </ErrorHandler>
        </React.Fragment>
    );
}


export const TasksByWorker = () => {
    const classes = useStyles();

    const cols = [
        "worker",
        "task_id",
        "lang",
        "queue",
        "status",
        "create_dt",
        "mod_ts"
    ];

    const taskCols = [
        "task_id",
        "lang",
        "queue",
        "status",
        "create_dt",
        "mod_ts"
    ]

    const [tasksByWorker, setTasksByWorker] = useState([]);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [sortKey, setSortKey] = useState('last_online_desc');
    const [workerVal, setWorkerVal] = useState("");
    const [taskStatusVal, setTaskStatusVal] = useState("");
    const [queues, setQueues] = useState([]);
    const [queueVal, setQueueVal] = useState("");
    const [langVal, setLangVal] = useState("");
    const [startDateVal, setStartDateVal] = useState("");
    const [endDateVal, setEndDateVal] = useState("");
    const [refresh, setRefresh] = useState(false);

    const updateTable = (
        sortKey,
        limit, 
        page,
        workerVal,
        taskStatusVal,
        queueVal,
        langVal,
        startDateVal,
        endDateVal
    ) => {
        const queryObject = createTasksByWorkerQueryObject(
                                sortKey,
                                limit, 
                                page,
                                workerVal,
                                taskStatusVal,
                                queueVal,
                                langVal,
                                startDateVal,
                                endDateVal
                            )
        fetchTasksByWorkerAndPrepare(queryObject).then((obj) => {
            setTotal(obj.meta.total);
            setTasksByWorker(obj.tasksByWorker);
        });
        fetchQueueList().then((queues) => {
            setQueues(queues);
        });
    }

    useEffect(() => {
        updateTable(
            sortKey,
            limit, 
            page,
            workerVal,
            taskStatusVal,
            queueVal,
            langVal,
            startDateVal,
            endDateVal
        );
        const autoUpdates = setInterval(() => {
            updateTable(
                sortKey,
                limit, 
                page,
                workerVal,
                taskStatusVal,
                queueVal,
                langVal,
                startDateVal,
                endDateVal
            );
        }, 10000)

        return () => clearInterval(autoUpdates);

    }, [sortKey,
        limit, 
        page, 
        total, 
        queueVal,
        langVal,
        taskStatusVal,
        workerVal,
        startDateVal,
        endDateVal,
        refresh]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
    };

    const handleSortKeyChange = () => {
        setSortKey(sortKey === 'last_online_desc' ? 'last_online_asc' : 'last_online_desc');
    }

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(1);
    }

    const handleStartDateVal = (event) => {
        setStartDateVal(event.target.value);
    }

    const handleEndDateVal = (event) => {
        setEndDateVal(event.target.value);
    }

    const handleReset = () => {
        setQueueVal("");
        setLangVal("");
        setStartDateVal("");
        setEndDateVal("");
        setTaskStatusVal("");
        setWorkerVal("");
    }

    const headRenders = {
        "worker": <TableSortLabel active direction={sortKey === 'last_online_desc' ? 'desc' : 'asc'} onClick={handleSortKeyChange}>Worker</TableSortLabel>,
        "task_id": "Task ID",
        "lang": "Language",
        "queue": "Queue",
        "status": "Task Status",
        "create_dt": "Date Created",
        "mod_ts": "Last Updated"
    };

    const bodyRenders = {
        "task_id": (task) => (<a href={dashboardUrl + "task/"+task.task_id} style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer">
                                <Button>{task.task_id}</Button>
                                </a>),
        "lang": (task) => task.lang,
        "queue": (task) => task.queue,
        "status": (task) => task.status,
        "create_dt": (task) => task.create_dt.toUTCString(),
        "mod_ts": (task) => task.mod_ts.toUTCString()
    };


    return (
        <React.Fragment>
             <Typography variant='h5'>
                <strong>Tasks By Worker</strong><IconButton onClick={() => setRefresh(!refresh)}><RefreshIcon /></IconButton>
            </Typography>
            <br />
            <ErrorHandler>
                <div>
                <Paper id='filters'>
                        <div style={{padding: "10px 20px 10px 20px"}}>
                            <Typography>
                                <strong>Filters</strong>
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField id="queue" label="Queue" value={queueVal} onChange={(event) => setQueueVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField id="language" label="Language" value={langVal} onChange={(event) => setLangVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="task-status-select-label">Task Status</InputLabel>
                                <Select
                                    labelId="task-status-select-label"
                                    id="task-status-select"
                                    value={taskStatusVal}
                                    onChange={(event) => setTaskStatusVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="1"><em>PROCESSING</em></MenuItem>
                                    <MenuItem value="2"><em>SUCCESS</em></MenuItem>
                                    <MenuItem value="3"><em>FAIL</em></MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField id="worker" label="Worker" value={workerVal} onChange={(event) => setWorkerVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Date Created-From" 
                                    value={startDateVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{max: endDateVal}}
                                    onChange={handleStartDateVal}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Date Created-To" 
                                    value={endDateVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{min: startDateVal}}
                                    onChange={handleEndDateVal}
                                />
                            </FormControl>
                            <br />
                            <FormControl className={classes.formControl}>
                                <ButtonGroup>
                                    <Button onClick={handleReset}>Reset</Button>
                                    {/* <Button onClick={handleExport}>Export</Button> */}
                                </ButtonGroup>
                            </FormControl>
                        </div>
                    </Paper>
                </div>
                <br />
                <Paper>
                    <TableContainer style={{maxHeight: '70vh'}}>
                        <Table style={{minWidth: 700}} stickyHeader size="small" aria-label="tasks by worker table">
                            <TableHead>
                                <TableRow>
                                    {cols.map((col) => {
                                        return <TableCell key={col}><strong>{headRenders[col]}</strong></TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasksByWorker.length > 0
                                    ? tasksByWorker.map((obj, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell rowSpan={obj.tasks.length + 1} key={index}><strong>{obj.worker}</strong></TableCell>
                                            </TableRow>
                                            {obj.tasks.map((task) => (
                                                <TableRow>
                                                    {taskCols.map((col) => <TableCell key={col}>{bodyRenders[col](task)}</TableCell>)}
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    ))
                                    :<TableRow>
                                        <TableCell align='center' colSpan={cols.length + 1}>
                                            <p style={{fontSize: 'large', margin: 0}}>No Tasks to display</p>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page-1} // TablePagination's pages start at 0, API's start at 1
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </ErrorHandler>
        </React.Fragment>
    );

}